 

* {
  padding: 0;
  margin: 0;
}

:root {
  /* colors */
  --bg1: rgb(40, 44, 52);
  --bg2: rgb(245, 245, 245);
  --navBg: rgb(20,20,20,1);
  --col1: rgb(245, 245, 245);
  --col2: rgb(10,10,10,1);

  /* sizes */
  --maxWidth: 1000px;
  --divPad: 150px;
  --divPadSide: 20px;
  --mb1: 50px;
  --mb2: 10px;


  background-color: var(--bg1);
  color: var(--col1);
  scroll-behavior: smooth;
}

h1 {
  font-size: 28px;
  pointer-events: none;
}

h2 {
  font-size: 24px;
  pointer-events: none;
}

p {
  font-size: 18px;
  pointer-events: none;
}

address {
  font-style: normal;
}

.pad1 {
  padding-bottom: var(--mb1);
}

.pad2 {
  padding-bottom: var(--mb2);
}

.pad2Top {
  padding-top: var(--mb2);
}

.borderB1 {
  border-bottom: 1px solid rgba(245,245,245,0.5);               
  width: 100%
}

.borderB1Top {
  border-Top: 1px solid rgba(245,245,245,0.5);               
  width: 100%
}

.borderB2 {
  border-bottom: 1px solid rgba(40,44,52,0.5);               
  width: 100%
}

.phoneLink {
  text-decoration: none;
  color: var(--col2);
}

.phone {
  text-decoration: none;
}

.phoneLink:hover .phone {
  text-decoration: underline;
}

.title {
  border: 2px solid var(--col1);
  border-radius: 20px;
  width: fit-content;
  padding: 7px 30px;
  font-size: 16px;
  font-weight: 500;
  transition: 1s;
  pointer-events: all;
  cursor: pointer;
}

.title2 {
  border: 2px solid var(--col2);
  border-radius: 20px;
  width: fit-content;
  padding: 7px 30px;
  font-size: 16px;
  font-weight: 500;
  transition: 1s;
  pointer-events: all;
  cursor: pointer;
}

.whiteT {
  background: var(--bg2);
  color: var(--col2);
}

.darkT {
  background: var(--bg1);
  color: var(--col1);
}


.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.container {
  padding: var(--divPad) 0px;
  max-width: var(--maxWidth);
  width: 100%;
}

.fc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fcl {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  gap: var(--mb2);
}

.fr {
  display: flex;
  flex-direction: row;
}

.frc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.gap1 {
  gap: var(--mb1);
}

.gap2 {
  gap: var(--mb2);
}

.gap3 {
  gap: 3px;
}

.fullW {
  width: 100%;
}

.page1{
  padding: 0px var(--divPadSide);
  background: var(--bg1);
  color: var(--col1);
}

.page2{
  padding: 0px var(--divPadSide);
  background: var(--bg2);
  color: var(--col2);
}

.menu {
  visibility: hidden;
}

.mobileBtn {
  visibility: visible;
}

@media screen and (min-width: 550px){
  .menu {
    visibility: visible;
  }

  .mobileBtn {
    visibility: hidden;
  }

  h1 {
    font-size: 35px;
    pointer-events: none;
  }
}

.mobileMenuHidden {
  visibility: hidden;
  opacity: 0;
}

.mobileMenuShow {
  animation: mobileMenuShowAnim 0.5s forwards;
}

@keyframes mobileMenuShowAnim {
  0%{
    visibility: visible;
    opacity: 0;
  }
  1%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

.mobileMenuHide {
  animation: mobileMenuHideAnim 0.5s forwards;
}

@keyframes mobileMenuHideAnim {
  0%{
    opacity: 1;
    visibility: visible;
  }
  90%{
    opacity: 0;
    visibility: visible;
  }
  100%{
    opacity: 0;
    visibility: hidden;
  }
}



.introHeight {
  height: 500px;
}

.serviceCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*width: 100%;*/
  gap: var(--mb1);

}

.sluzby {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*width: 100%;*/
  gap: var(--mb1);
}

.map {
  border-color: transparent;
  width: 100%;
  height: 250px;
}


.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: var(--mb2);
}

.mainLottie {
  width: 300px;
}


@media screen and (min-width: 600px){

  .introHeight {
    height: 600px;
  }

  .map {
    border-color: transparent;
    width: 400px;
  }

  .serviceCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: normal;
    width: 100%;
  }

  .sluzby {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*width: 100%;*/
    gap: 0px;
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--mb2);
    padding: 25px 10px;
    cursor: pointer;
  }

  .card:hover {
    background: rgba(255,255,255,0.1);
    scale: 1.0;
    transition: 0.2s;
  }

  .mainLottie {
    width: 400px;
  }
}

.sluzbyAnimHidden {
  opacity: 1;
  scale: 1;
  transform: translateY(0px);
  animation: sluzbyAnimationHide 0.4s ease-out forwards;
}

.sluzbyAnimShow {
  opacity: 0;
  scale: 0.8;
  transform: translateY(50px);
  animation: sluzbyAnimationShow 0.7s ease-out forwards;
}

@keyframes sluzbyAnimationShow {
  0% {
    opacity: 0;
    scale: 0.8;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    scale: 1;
    transform: translateY(0px);
  }
}

@keyframes sluzbyAnimationHide {
  0% {
    opacity: 1;
    scale: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    scale: 0.8;
    transform: translateY(50px);
  }
}


.kontaktAnimHidden {
  opacity: 1;
  transform: translateX(0px);
  animation: kontaktAnimationHide 0.4s ease-out forwards;
}

.kontaktAnimShow {
  opacity: 0;
  transform: translateX(-100px);
  animation: kontaktAnimationShow 0.7s ease-out forwards;
}

@keyframes kontaktAnimationShow {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes kontaktAnimationHide {
  0% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(-100px);
  }
}



.cardTopic {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  transform: scaleX(1);
}



.card:hover .cardTopic {
  transform: scaleX(1);
  animation: rotateAnim 0.5s ease-in;
}

@keyframes rotateAnim {
  0%{
    transform: scaleX(1);
  }
  50%{
    transform: scaleX(-1);
  }
  100% {
    transform: scaleX(1);
  }
  
}

.scrollMarginTop {
  scroll-margin-top: 60px;
}

.navBtn {
  background: transparent;
  color: white;
  border: none;
  font-size: 18px;
  padding: 5px;
  font-weight: bold;
  cursor: pointer;
  text-underline-offset: 5px; 
}

.goUpHidden {
  bottom: -70px;
  opacity: 1;
}

.goUpShow {
  animation: upAnimShow 1s forwards;
}

.goUpShow:active {
  scale: 1.05;
}

@keyframes upAnimShow {
  0%{
    bottom: -70px;
    opacity: 0;
  }
  100%{
    bottom: 10px;
    opacity: 1;
  }
}

.goUpHide {
  animation: upAnimHide 1s forwards;
}

@keyframes upAnimHide {
  0%{
    bottom: 10px;
    opacity: 1;
  }
  100%{
    bottom: -70px;
    opacity: 0;
  }
}

.textPathDefault {

  font-size: 50px;
  font-weight: bold;

  fill: rgba(245, 245, 245, 1); 
  opacity: 1;
}

.textPath {
  stroke: rgb(245, 245, 245);
  font-size: 50px;
  font-weight: bold;
  stroke-width: 2px;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  fill: transparent;
  animation: dash 3s linear forwards;
  opacity: 1;
}

@keyframes dash {
  from {
    stroke-dashoffset: 1000;
    stroke: rgba(245, 245, 245, 0);
  }
  50%{
    stroke-dashoffset: 850;
    fill: rgba(245, 245, 245, 0);
    stroke: rgba(245, 245, 245, 1);
  }
  100% {
    stroke-dashoffset: 500;
    fill: rgba(245, 245, 245, 1); 
    stroke: rgba(245, 245, 245, 0);
  }
}

.introUnderlineDefault {
  width: 0%;
  height: 4px;
  border-radius: 4px;
  background: rgb(245, 245, 245);
  margin-top: -7px;
  opacity: 0;
}

.introUnderline {
  width: 0%;
  height: 4px;
  border-radius: 2.5px;
  background: rgb(245, 245, 245);
  margin-top: -7px;
  animation: introUnderlineAnim 1s forwards 4s;
  opacity: 0;
}

@keyframes introUnderlineAnim {
  0% {
    width: 0%;
    opacity: 0;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}

@media screen and (min-width: 500px){
  .introUnderline {
    width: 0%;
    height: 4px;
    border-radius: 4px;
    background: rgb(245, 245, 245);
    margin-top: -7px;
    animation: introUnderlineAnim 1s forwards 2s;
    opacity: 0;
  }
}

.carAnimationDefault {
  transform: scaleX(-1);
  position: relative;
  max-width: 400px;
  width: 100%;
  z-index: 10;
}

.carMotionDefault {
  /*margin-left: -700px; */
  transform: translateX(-700px);
}

.carMotion {
  /*margin-left: -700px; */

  animation: carAnim 3s linear forwards;
}


@keyframes carAnim {
  0% {
    opacity: 1;
    transform: translateX(-700px);
  }
  99% {
    transform: translateX(100vw);
    opacity: 1;
  }
  100% {
    transform: translateX(100vw);
    opacity: 0;
  }
}

.introTitleDefault {
  background: rgba(40, 44, 52, 1);
  width: 100%;
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.introTitle {
  background: rgba(40, 44, 52, 1);
  width: 100%;
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: introColor 2s forwards 2.5s;
}

@keyframes introColor {
  0% {
    background: rgba(40, 44, 52, 1);
  }
  100% {
    background: rgba(40, 44, 52, 0.8);
  }
}


.galeryImg {

  scroll-snap-align: center;
  object-fit: cover;

}



/* GOOGLE MAPS*/

.mapsContainer {
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.showInMapsButton {
  position: absolute;
  margin-top: 90px;
  background: var(--col1);
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.showInMapsButton a {
  text-decoration: none;
}

.showInMapsButton p {
  font-weight: 500;
  color: var(--col2);
  text-decoration: underline;
}



.showInMapsDefault {
  opacity: 0;
  visibility: hidden;
}

.showInMapsShow {
  opacity: 0;
  visibility: hidden;
  animation: showInMapsShowAnim 0.5s forwards;
}

@keyframes showInMapsShowAnim {
  0%{
    visibility: hidden;
    opacity: 0;
  }
  5%{
    visibility: visible;
    opacity: 0;
  }
  100%{
    visibility: visible;
    opacity: 1;
  }
}

.showInMapsHide {
  opacity: 1;
  visibility: visible;
  animation: showInMapsHideAnim 0.5s forwards;
}

@keyframes showInMapsHideAnim {
  0%{
    visibility: visible;
    opacity: 1;
  }
  95%{
    visibility: visible;
    opacity: 0;
  }
  100%{
    visibility: hidden;
    opacity: 0;
  }
}